import React from "react"
import styled from 'styled-components'
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import SEO from "../components/seo"
import SuccessCheck from "../components/Icons/SuccessCheck"
import { Success, Description } from "../components/FormStyle"

const Wrapper = styled.div`
	padding: 200px 100px;
	min-height: 100vh;
	background: #EBF8FF;
`

const Thanks = ({ data, location }) => (
  <Layout location={location}>
	<Helmet><meta name="robots" content="noindex, nofollow" /></Helmet>
    <SEO title="thanks" />
    <Wrapper>
	    <Description textAlign="center" color="#062C44">
		    <Success><SuccessCheck /></Success>
		    Thank you for connecting with us <br/> Our Representative will get back to you with the requested building quote soon...
	  	</Description>
	  </Wrapper>
  </Layout>
)

export default Thanks
